import React from "react";
import {AudioIcon, VideoIcon} from "../icons/icons";
import VideoStatusWithName from "./VideoStatusWithName";

class MainVideoView extends React.Component {
  componentDidMount = () => {
    const { stream } = this.props;
    if(typeof(stream) !== 'MediaStream') {
      console.error('stream not set')
      return;
    }

    this.video.srcObject = stream;
  };

  componentWillUnmount = () => {
    this.video.srcObject = null;
  }

  render = () => {
    const { id, stream, vidFit } = this.props;
    const fitClass = vidFit ? "fit-vid" : ""
    return (
      <div className="main-video-layout">
        <video
          ref={ref => {
            this.video = ref;
          }}
          id={id}
          autoPlay
          playsInline
          muted={false}
          className={"main-video-size " + fitClass}
        />
        <VideoStatusWithName type="main" audioEnabled={!stream.muted} videoEnabled={!stream.video}
        label={stream.info.name} />
      </div>
    );
  };
}

export default MainVideoView;
